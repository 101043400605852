import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/dea.mp3'
import logoig from '../assets/img/dinny/logoig.svg'
import bismillah from '../assets/img/dea/bismillah.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/fitri/covid.svg'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components'
let cmain = '#87d6d1'
let orange = '#2a4e35'
let black = 'rgb(38,38,38)'


let id = 'indy-ezi'
let inisial_co = 'Ezi'
let inisial_ce = 'Indy'
let lengkap_co = 'Vahrezi Ariq Nasution, S.T'
let lengkap_ce = 'Indy Larasati Wardhana, S.Ked'
let bapak_co = 'Bpk. Ir. Ismet Danial Nasution'
let ibu_co = 'Ibu dr. Vaura Desriana Izhar'
let bapak_ce = "Bpk. Iwan Krishna Wardhana, BA, BBus, MA "
let ibu_ce = "Ibu Retno Wuri Handayani"
let ig_co = "Vahreziarq"
let ig_ce = "Indyywrdhn"

let foto_ce = "https://i.ibb.co/WHrgx9M/Individu-2.jpg"
let foto_co = "https://i.ibb.co/wRYZWc4/Individu-1.jpg"
let waktunikah = "03/07/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/eyrBvwieW9EQ71RY7"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MGQ3ZTZhYWxlNGd0aDI1ZXAxb3JiNDQ0dDkgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        var countDownDate = new Date("2021","2","7","7","30").getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })


        }, 1000);

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }


    }
    render() {
       
        let slide = [
            "1.jpg",
            "2.jpg",
            "3.jpg",
            "4.jpg",
            "5.jpg",
            "6.jpg",
            "7.jpg",
            "8.jpg",
            "9.jpg",
            "10.jpg",

        ]
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showrek } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undang.in/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: cmain
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent',
                        position:'relative'
                    }}>
                        <Container  fluid id='g3-header' className={`container-fluid position-relative indy-ezi `} style={{
                            zIndex:1,
                        }}>
                            <div className="position-absolute" style={{ left: 0, bottom: '10%', width: '100vw' }}>
                                <Item>
                                    <Col xs={12} md={4} className='m-2 m-md-0 '>
                                        <img className='img-fluid w-100 p-2'
                                            src={openlogo} data-aos="fade-left" data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    {
                                        <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`}
                                            style={{ marginTop: '0' }} data-aos="fade-right">
                                            Yth :<br /> {query ? query : ''} <br /></h2>
                                    }</Item>
                                <Row className='justify-content-center'>
                                    <div onClick={() => { this.play() }}
                                        // data-aos="fade-left"
                                        className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                        style={{ marginTop: 0, color: 'white' }}>
                                        Open Invitation
                            </div>
                                </Row>

                            </div>
                        </Container>
                        <Container fluid id='g3-header' className='position-fixed' style={{
                                    zIndex:'0',
                                    top:0,left:0,
                                    backgroundImage: `url('${pw(id,"bg.jpg")}')`
                                }}>
                                </Container>

                        <div className={hide ? 'd-none' : 'd-block'} style={{zIndex:'-1'}}>
                            <div id="top" style={{ backgroundColor: 'transparent',position:'relative' }}>
                                
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>
                                        <Col xs={10} md={4}>
                                            <img src={pw(id,"bismillah.svg")} className="img-fluid w-100" />
                                        </Col>
                                        <p className="fs16 text-center  px-3 py-3 w-100 text-center"
                                            style={{ color: orange, fontFamily: 'Tinos,serif' }} data-aos="fade-left">
                                            Assalaamu'alaikum Warahmatullahi  Wabarakatuh<br /><br />
                                            Dengan memohon rahmat dan ridha Allah SWT,<br />
                                            kami bermaksud menyelenggarakan pernikahan putra-putri kami
                                        </p>
                                    </Item>
                                    {/* <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: 'white'}}>
                                            Pernikahan {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                 */}
                                </Container>
                                {/* <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain}}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" 
                                                    style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari:</b><br />
                                                        {bapak_ce}  <br />
                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{ fontSize: '32px', 
                                                    fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container> */}
                                <Container style={{ color: 'white' }} className="py-5">
                                    <Item>
                                        <Col md={5}>
                                            <h1 className="w-100 text-center"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive',
                                                    fontWeight: 700,
                                                    color: orange,
                                                    fontSize: '22px',
                                                }} data-aos="fade-left">
                                                {lengkap_ce}
                                            </h1>
                                            <Item>
                                                <img src={pw(id, "logoig.svg")} className='btn p-0 my-3'
                                                    data-aos="fade-right"
                                                    onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />
                                            </Item>
                                            <p className="w-100 text-center" style={{ fontSize: '16px', fontFamily: 'Tinos,serif',color:orange}}>
                                                Putri dari :<br />
                                                {bapak_ce}<br />
                                                & {ibu_ce}
                                            </p>
                                        </Col>
                                        <Col md={2} className="pb-3">
                                            <Row className="h-100">
                                                <h1 className="w-100 text-center my-auto"
                                                    style={{
                                                        fontFamily: 'Parisienne, cursive',
                                                        fontWeight: 700,
                                                        fontSize: '22px',
                                                        color: orange
                                                    }} data-aos="fade-left">
                                                    &
                                            </h1>
                                            </Row>
                                        </Col>
                                        <Col md={5}>
                                            <h1 className="w-100 text-center"
                                                data-aos="fade-right"
                                                style={{
                                                    fontFamily: 'Parisienne, cursive',
                                                    fontWeight: 700,
                                                    fontSize: '22px',
                                                    color: orange
                                                }}>
                                                {lengkap_co}
                                            </h1>


                                            <Item>
                                                <img src={pw(id, "logoig.svg")} className='btn p-0 my-3'
                                                    onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />
                                            </Item>
                                            <p className="w-100 text-center" data-aos="fade-left"
                                                style={{ fontSize: '16px', fontFamily: 'Tinos,serif',color:orange }}>
                                                Putra dari :<br />{bapak_co}<br />
                                                & {ibu_co}
                                            </p>
                                        </Col>
                                    </Item>
                                </Container>

                                <Container fluid className="text-center px-4 dinny py-3"
                                    style={{ color: orange }} >
                                    <Item>
                                        <p className="fs16" style={{ fontFamily: 'Tinos,serif'}} data-aos="zoom-in" >
                                            Yang Insyaa Allah akan dilaksanakan pada:
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20" style={{}} data-aos="fade-left" style={{ fontFamily: 'Tinos,serif' }}>
                                            <b>
                                                Minggu <span className="fs36">07</span> Maret 2021
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: orange, fontFamily: 'Tinos,serif' }} data-aos="fade-right">
                                            <b style={{ color: orange }}>Akad Nikah </b><br />
                                            <span className="fs16">
                                                <br />
                                                07.00-10.00 (keluarga)<br/>
10.00-12.00 (kerabat)<br/>
                                             </span>
                                             <Button style={{backgroundColor:'rgb(42, 78, 53)'}} 
                                             className="mt-3"
                                             onClick={()=>{
                                                window.location.href='https://youtu.be/L_cGp9EX19I'
                                            }}>
                                                Live Streaming Akad
                                            </Button>

                                        </p>
                                        
                                    </Item>
                                    <Item>
                                    

                                    </Item>
                                    
                                    
                                    <Item>
                                        
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${orange}`,
                                                borderRadius: '10px'
                                            }}
                                            data-aos="fade-left"

                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: orange, fontFamily: 'Tinos,serif' }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Container id='sectiongold57'>

                                    <div className='pt-3' data-aos="fade-right">

                                        <Item>
                                            <div data-aos="fade-left" data-aos-duration="1000"
                                                className='col-10 col-lg-8 kotak'
                                                style={{ backgroundColor: orange }}>
                                                <Item>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {days}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Days
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {hours}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Hours
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot'>:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div >
                                                                {minutes}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Mins
                      </span>
                                                        </Item>
                                                    </div>
                                                    <div className='dot' >:</div>
                                                    <div className='item'>
                                                        <Item>
                                                            <div>
                                                                {seconds}
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <span>
                                                                Secs
                      </span>
                                                        </Item>
                                                    </div>
                                                </Item>

                                            </div>
                                        </Item>
                                    </div></Container>
                                {/* <Container className="text-center py-5 dinny" id="dinny">
                                    <>
                                        <Item>
                                            <h1 style={{
                                                fontSize: '72px',
                                                fontFamily: "'Marck Script', cursive",
                                                color: cmain
                                            }}>
                                                Rundown
                          </h1>
                                        </Item>

                                        <Item>
                                            <Col sm={2} xs={4}>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/2905/2905065.svg" className="img-fluid w-100 p-2" />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <p className="cblack fs16">
                                                <b>
                                                    08.00 - 10.00
                        </b><br />
                        Akad Nikah
                      </p>
                                        </Item>
                                        <Item>
                                            <Col sm={4} className="pt-sm-3">
                                                <Item>
                                                    <Col xs={8} className="order-2 order-sm-1">
                                                        <p className="cblack text-left text-sm-right fs16">
                                                            <b>
                                                                11.00 - 11.15
                              </b><br />
                              Wedding Entrance

                            </p>
                                                    </Col>
                                                    <Col sm={4} xs={3} className="order-1 order-sm-2">
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                                                    </Col>
                                                </Item>
                                                <div className="d-block opa">
                                                    <Item>
                                                        <Col xs={3} sm={4} >
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                                                        </Col>
                                                        <Col xs={8}>
                                                            <p className="cblack text-left fs16">
                                                                <b>
                                                                    11.00 - 11.30
                              </b><br />
                              Photo Session
                            </p>
                                                        </Col>
                                                    </Item>
                                                </div>

                                                <Item>
                                                    <Col xs={8} className="order-2 order-sm-1">
                                                        <p className="cblack text-left text-sm-right fs16">
                                                            <b>
                                                                11.00 - 13.00
                              </b><br />
                              Enjoy the live music performance
                            </p>
                                                    </Col>
                                                    <Col sm={4} xs={3} className="order-1 order-sm-2">
                                                        <img src="https://www.flaticon.com/svg/static/icons/svg/926/926338.svg" className="img-fluid w-100" />
                                                    </Col>
                                                </Item>


                                            </Col>


                                            <Col sm={4} className="pt-sm-3">

                                                <div style={{ opacity: 0 }} className="d-none d-sm-block">
                                                    <Item>
                                                        <Col sm={8}>
                                                            <p className="cblack text-right fs16">
                                                                <b>
                                                                    11.00 - 11.15
                              </b><br />
                              Wedding Entrance

                            </p>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1110/1110062.svg" className="img-fluid w-100" />
                                                        </Col>
                                                    </Item>
                                                </div>
                                                <div>

                                                </div>
                                                <div className="d-none d-sm-block">
                                                    <Item>
                                                        <Col sm={4} xs={3}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/1473/1473885.svg" className="img-fluid w-100" />
                                                        </Col>
                                                        <Col xs={8} >
                                                            <p className="cblack text-left fs16">
                                                                <b>
                                                                    11.00 - 11.30
                              </b><br />
                             Family Photo Session
                            </p>
                                                        </Col>

                                                    </Item>
                                                </div>
                                                <div style={{ opacity: 0 }} className="d-none d-sm-block">
                                                    <Item>

                                                        <Col sm={8}>
                                                            <p className="cblack text-right fs16">
                                                                <b>
                                                                    11.00 - 13.00
                              </b><br />
                              Enjoy the live music performance
                            </p>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <img src="https://www.flaticon.com/svg/static/icons/svg/926/926338.svg" className="img-fluid w-100" />
                                                        </Col>
                                                    </Item>
                                                </div>





                                            </Col>
                                        </Item>
                                    </>
                                </Container> */}

                                <Container className="py-3" data-aos="fade-right">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw("asset","covid-green.svg")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>
                                <Container fluid >
                                    <Container className=" px-md-5 col-md-10 px-3"
                                        style={{ color: cmain, fontFamily: 'Josefin Sans, sans-serif' }}
                                    >

                                        <Row className="position-relative px-2 py-3">
                                            <h1 className="peri w-100 text-center"
                                                style={{ fontWeight: 700, color: orange, fontSize: '22px', }}>
                                                Our Love Story
                                            </h1>
                                            <Container id="story-reni" className="p-0">
                                                <div className="container p-0">

                                                    <ul className="timeline">
                                                        {[
                                                            (<><b>Locked eyes (2013)</b><br />
                                                            They walk the same ground, locked each other's eyes<br/>
They were just strangers, unable to realize<br/>
What god has planned for them, as the time flies 
                                                                    </>),
                                                            (<><b>Friendzone (2014-2015)</b><br />
                                                            They adore each other, the bestest of friend<br />
Spend time together until school times end.<br />
Share each other's dreams, feels what the other feels.<br />
But too prideful to admit what they truly feels.</>),
                                                            (<><b>Highschool Sweethearts (2015 - 2016)</b><br />
                                                            Their highschool became the witness,<br />
How their connection can be so precious.<br />
As they agreed to be honest,<br />
All they can say is "thank goodness".<br />
For as the person she wanted to be with,<br />
Is the person that wanted her to be his<br />
Through sweats, tears, and stressful nights,<br />
They passed highschool together with all their might.</>),
                                                            (<><b>Dreams and Hardship (2016-2017)</b><br />
                                                            Even though their faculty is different,<br />
They're blessed to attend the same university.<br />
A place where knowledge is abundant, and where they can bloom their maturity.<br />
Both get demanding, and life's full of troubles,<br />
Because new surroundings, new problems.<br />
One by one, they notice their imperfection.<br />
Day by day, they question their decision.
</>), 
(<><b>Acceptance (2018-2019)</b><br />
                                                            Everyone searches for their home,<br />
Through pain and bitterness, that's where they longing for.<br />
For him and her, each other is home,<br />
As for each problem passed, their love only grew more.<br />
Cause there's one believe they always hold firm,<br />
That at the end of the day,<br />
There's only the two of them</>),
                                                            (<><b>Committed (2020)</b><br />
                                                            5 years the relationship has established,<br />
They came to the realization<br />
Flaws, difference, and arguments they had<br />
Is what led their connection reach perfection<br />
They committed to face this life<br />
Side by side, as a couple<br />
With permission of their mother and father,<br />
There comes engagement between this lover
</>),

(<><b>Wedding Day (March 7th, 2021)</b><br />
                                                            The day when two souls are united,<br />
bound in sacred promises witnessed,<br />
before Allah SWT and their loved ones,<br />
With faith and love until jannah comes
</>)
                                                        ].map((v, i) => {

                                                            return (

                                                                <li className={i % 2 ? "timeline-inverted" : ''} >
                                                                    <div className="timeline-badge d-none d-md-block" style={{ backgroundColor: '#FE646F' }}>
                                                                        <img src={pw("asset", "heart.svg")} style={{ height: '35px', witdh: '35px' }} />
                                                                    </div>
                                                                    <Col xs={12} md={5} className="timeline-panel" style={{ backgroundColor: 'white' }}>
                                                                        <div className="timeline-body">
                                                                            <p style={{ fontSize: '16px', fontFamily: 'Tinos,serif',color:orange }}>{v}</p>
                                                                        </div>
                                                                    </Col>
                                                                </li>

                                                            )
                                                            i++
                                                        })}
                                                    </ul>
                                                </div></Container>
                                        </Row>
                                    </Container>


                                </Container>
                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} data-aos="fade-right" />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3' data-aos="fade-left">

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14' style={{ fontSize: '16px',color:orange }}>
                                                            Jika Allah berkehendak menyatukan dua hati, maka keduanya pasti akan bersatu walaupun diantara keduanya terbentang langit dan bumi.
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3' data-aos="fade-right">
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: 'Parisienne, cursive',
                                                        fontWeight: 700,
                                                        color: orange
                                                    }}>
                                                        Doa Untuk Kedua Mempelai
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                       
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white', fontFamily: 'Tinos,serif' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container fluid style={{ backgroundColor: cmain, color: 'white' }} className="py-3">
                                    <Item>
                                        <Button style={{ backgroundColor: '#2a4e35', borderColor: '#2a4e35' }} onClick={() => {
                                            this.setState({ showrek: true })
                                        }}>
                                            <Item>
                                                <p className="px-2 mb-0" style={{ fontSize: '14px', fontFamily: 'Tinos,serif' }}>
                                                    Send Gift <img src='https://www.flaticon.com/svg/static/icons/svg/837/837891.svg' style={{ width: '25px', height: '25px', marginLeft: '10px' }} />

                                                </p>
                                            </Item>
                                        </Button>
                                    </Item>
                                    
                                            <Item>
                                                <p className="text-center mx-5 py-3" style={{ fontSize: '16px', fontFamily: 'Tinos,serif' }}>
                                                    Tanpa mengurangi rasa hormat, bagi anda yang ingin memberikan tanda kasih untuk pengantin, dapat melalui:
                                    </p>
                                            </Item>
                                            <Item>
                                                <div className="p-3 px-5" style={{ backgroundColor: 'white' }}>
                                                    <Item>
                                                        <p className="text-center" style={{
                                                            fontSize: '16px',
                                                            fontFamily: 'Tinos,serif', color: '#2a4e35'
                                                        }}>
                                                            <b>6030953779</b><br />
                                                Bank BCA<br />
                                                a.n. Indy Larasati Wardhana
                                              

                                            </p>
                                                    </Item>
                                                    <Item>
                                                        <CopyToClipboard text="6030953779">
                                                            <div className="position-relative">

                                                                <Button style={{ backgroundColor: '#2a4e35', borderColor: '#2a4e35' }} onClick={() => {
                                                                    this.setState({ copy: true })
                                                                }}>
                                                                    <Item>
                                                                        <p className="px-2 mb-0" style={{ fontSize: '14px', fontFamily: 'Tinos,serif' }}>
                                                                            {this.state.copy ? 'Berhasil di Copy ke Clipboard' : 'Copy Nomor Rekening'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{ width: '25px', height: '25px', marginLeft: '10px' }} />

                                                                        </p>
                                                                    </Item>
                                                                </Button>


                                                            </div>
                                                        </CopyToClipboard>
                                                    </Item>

                                                </div>
                                            </Item>
                                        
                                       
                                </Container>
                                <Foot ig={pw(id, "logoig.svg")} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

